import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Airco from "../views/Airco.vue";
import Isolatiev2 from "../views/Isolatiev2.vue";
import Isolatie from "../views/Isolatie.vue";
import Zonnepanelenv2 from "../views/Zonnepanelenv2.vue";
import Zonnepanelen from "../views/Zonnepanelen.vue";
Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home,
  // },
  // {
  //   path: "/",
  //   redirect: "/airco",
  // },
  // {
  //   path: "/airco",
  //   name: "Airco",
  //   component: Airco,
  // },
  // {
  //   path: "/isolatie",
  //   name: "Isolatie",
  //   component: Isolatie,
  // },
  // {
  //   path: "/isolatiev2",
  //   name: "Isolatie",
  //   component: Isolatiev2,
  // },
  // {
  //   path: "/zonnepanelen",
  //   name: "Zonnepanelen",
  //   component: Zonnepanelen,
  // },
  // {
  //   path: "/zonnepanelenv2",
  //   name: "Zonnepanelen",
  //   component: Zonnepanelenv2,
  // },
  {
    path: "/Bedankt",
    name: "Bedankt",
    component: () => import("../views/Bedankt.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
